/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 ./public/models/mountc.gltf 
*/

import { useGLTF } from "@react-three/drei";
import React  from "react";


export function Mountc(props) {
  const { nodes, materials } = useGLTF("/models/mountc.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Node1.geometry} material={materials.x1} />
    </group>
  );
}

useGLTF.preload("/models/mountc.gltf");
