import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import CounterAnimation from "./components/CounterAnimation/CounterAnimation";
import IndexPage from "./components/indexPage/IndexPage";
import AboutPage from "./components/aboutPage/AboutPage";
import ContactUsPage from "./components/contactUsPage/ContactUsPage";
import BackendPage from "./components/backendPage/BackendPage";
import FrontendPage from "./components/frontendPage/FrontendPage";

// Dashboard Imports
import DashBoardlayout from "./Dashboard/Components/DashBoard_layout";
import Chat from "./Dashboard/Pages/Chat";
import ChatBoat_codeGenerate from "./Dashboard/Pages/ChatBoat_codeGenerate";
import ChatBotScreen from "./Dashboard/Pages/ChatBotScreen";
import ChatBotScreen2 from "./Dashboard/Pages/ChatBotScreen_2";
import CompanyProfile from "./Dashboard/Pages/Company_Profile";
import Dashboard from "./Dashboard/Pages/Dashboard";
import EmployeePage from "./Dashboard/Pages/EmployeePage";
import ModalCreate from "./Dashboard/Pages/Modal_Create";
import Notifications from "./Dashboard/Pages/Notification";
import Payment from "./Dashboard/Pages/Payment";
import Setting from "./Dashboard/Pages/Setting";
import ModalChat from "./Dashboard/Pages/Modal_Chat";
import AddUser from "./Dashboard/Pages/AddUser";
import UserDetails from "./Dashboard/Pages/UserDetails";
import AllUser from "./Dashboard/Pages/AllUser";
import UserPayment from "./Dashboard/Pages/UserPayment";
import AlluserPayment from "./Dashboard/Pages/AlluserPayment";
import PlanDetail from "./Dashboard/Pages/PlanDetail";
import ViewallQandA from "./Dashboard/Pages//ViewallQandA";
import Signup from "./Screens/signup/signup";
import Login from "./Screens/LoginPage/login";

const App = () => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [loadingduration, setloadingduration] = useState(6200);

  // useEffect(() => {
  //   setTimeout(() => setIsLoading(false), loadingduration);
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/AboutUs" element={<AboutPage />} />
        <Route path="/ContactUs" element={<ContactUsPage />} />
        <Route path="/BackendPage" element={<BackendPage />} />
        <Route path="/FrontendPage" element={<FrontendPage />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Signin" element={<Login />} />

        <Route path="/dashboard" element={<DashBoardlayout />}>
          <Route path="/dashboard/" element={<Dashboard />} />
          <Route path="/dashboard/Profile" element={<CompanyProfile />} />
          <Route path="/dashboard/Modal" element={<ModalCreate />}>
            <Route path="/dashboard/Modal/Modalchat" element={<ModalChat />} />
            <Route
              path="/dashboard/Modal/ViewallQandA"
              element={<ViewallQandA />}
            />
          </Route>
          <Route path="/dashboard/EmloyeeSettings" element={<EmployeePage />} />
          <Route path="/dashboard/Payment" element={<Payment />} />
          <Route path="/dashboard/Chat" element={<Chat />} />
          <Route path="/dashboard/ChatBot" element={<ChatBotScreen />}>
            <Route
              path="/dashboard/ChatBot/chat"
              element={<ChatBotScreen2 />}
            />
            <Route
              path="/dashboard/ChatBot/chat/CodeGenerate"
              element={<ChatBoat_codeGenerate />}
            />
          </Route>
          <Route path="/dashboard/Setting" element={<Setting />} />
          <Route path="/dashboard/Notifications" element={<Notifications />} />
          <Route path="/dashboard/AddUser" element={<AddUser />} />
          <Route path="/dashboard/AllUser" element={<AllUser />}>
            <Route
              path="/dashboard/AllUser/UserPayment"
              element={<UserPayment />}
            />
          </Route>
          <Route path="/dashboard/UserDetails" element={<UserDetails />} />
          <Route path="/dashboard/Paymentdetail" element={<AlluserPayment />}>
            <Route
              path="/dashboard/Paymentdetail/PlanDetail"
              element={<PlanDetail />}
            />
          </Route>
        </Route>
      </Routes>
    </>

    // <>
    //   {isLoading ? (
    //     <CounterAnimation loadingduration={loadingduration} />
    //   ) : (
    //     <Routes>
    //       <Route path="/" element={<IndexPage />} />
    //       <Route path="/AboutUs" element={<AboutPage />} />
    //       <Route path="/ContactUs" element={<ContactUsPage />} />
    //       <Route path="/BackendPage" element={<BackendPage />} />
    //       <Route path="/FrontendPage" element={<FrontendPage />} />
    //     </Routes>
    //   )}
    // </>
  );
};

export default App;
