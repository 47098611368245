/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 ./public/models/mesha.gltf 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Mesha(props) {
  const { nodes, materials } = useGLTF('/models/mesha.gltf')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Node1.geometry} material={materials.x1} />
    </group>
  )
}

useGLTF.preload('/models/mesha.gltf')
